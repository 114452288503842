import apiClient from "./apiClient";

export const dashboardService = {
  getDashboardMetrics: async (timeLimit) => {
    try {
      const response = await apiClient.get(
        "/api/dashboard/get_dashboard_details",
        {
          params: { time_limit: timeLimit },
        }
      );
      return response.data.data || response.data;
    } catch (error) {
      throw error.response?.data?.message || "Failed to fetch metrics";
    }
  },

  getProjects: async (timeLimit) => {
    try {
      const response = await apiClient.get("/api/dashboard/get_projects", {
        params: { time_limit: timeLimit },
      });
      return response.data.data || response.data;
    } catch (error) {
      throw error.response?.data?.message || "Failed to fetch projects";
    }
  },

  createProject: async (projectData) => {
    try {
      const formData = new FormData();
      Object.entries(projectData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      const response = await apiClient.post(
        "/api/dashboard/create_new_project",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error.response?.data?.message || "Failed to create project";
    }
  },
};
