import React from "react";
import { Alert } from "@mui/material";

const Error = ({ error }) => {
  return (
    <Alert severity="error" className="mb-4">
      {error}
    </Alert>
  );
};

export default Error;
