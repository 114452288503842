import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button, Alert, Typography } from "@mui/material";
import logo from "../assets/logo.png";
import background from "../assets/background.jpg";
import { authService } from "../api/authService";

function Signup() {
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  // Allowed email domains
  const allowedDomains = ["tataprojects.com", "tsmg.com", "b2boptimize.ai"];

  // Function to check if email domain is valid
  const isEmailDomainValid = (email) => {
    const emailDomain = email.split("@")[1];
    return allowedDomains.includes(emailDomain);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Clear previous messages and errors
    setMessage("");
    setError("");

    // Validate email domain
    if (!isEmailDomainValid(email)) {
      setError(
        "This domain is invalid, please ensure that your organization has the proper credentials to access the platform."
      );
      return; // Stop further processing
    }

    try {
      const response = await authService.signup({
        user_name: userName,
        email,
        password,
      });
      if (response.message === "success") {
        setMessage(
          "Your signup request is received. You'll receive an email once your account is approved."
        );
        // Redirect to login page after showing the message
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        throw new Error(response.message || "Signup failed. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      setError(error.message || "An error occurred. Please try again later.");
    }
  };

  return (
    <div
      className="flex justify-center items-center min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="bg-white shadow-lg rounded-xl p-6 w-11/12 sm:w-96">
        <form onSubmit={handleSubmit}>
          <div className="text-center mb-6">
            <img src={logo} alt="App Logo" className="mx-auto h-24 w-auto" />
            <Typography variant="h5" className="font-bold mt-2">
              SIGN UP
            </Typography>
          </div>

          {message && (
            <Alert severity="success" className="mb-4">
              {message}
            </Alert>
          )}
          {error && (
            <Alert severity="error" className="mb-4">
              {error}
            </Alert>
          )}

          <div className="flex flex-col gap-1 mb-4">
            <TextField
              fullWidth
              label="User Name"
              name="userName"
              type="text"
              size="small"
              margin="dense"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
            <TextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              size="small"
              margin="dense"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              fullWidth
              label="Password"
              name="password"
              type="password"
              size="small"
              margin="dense"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <Button fullWidth type="submit" variant="contained">
            SIGN UP
          </Button>

          <Typography variant="body2" className="text-center pt-4">
            Already have an account?{" "}
            <span
              className="text-blue-600 cursor-pointer font-bold hover:underline"
              onClick={() => navigate("/")}
            >
              Log in
            </span>
          </Typography>
        </form>
      </div>
    </div>
  );
}

export default Signup;
