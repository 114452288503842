import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Signup from "./pages/Signup";
import Dashboard from "./pages/Dashboard";
import ProjectPage from "./pages/ProjectPage";
import Layout from "./components/Layout";
import Integrations from "./pages/Integrations";
import Support from "./pages/Support";
import Notifications from "./pages/Notifications";
import UserSettings from "./pages/UserSettings";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/dashboard"
          element={
            <Layout>
              <Dashboard />
            </Layout>
          }
        />
        <Route
          path="/project/:projectId"
          element={
            <Layout>
              <ProjectPage />
            </Layout>
          }
        />
        <Route
          path="/integrations"
          element={
            <Layout>
              <Integrations />
            </Layout>
          }
        />
        <Route
          path="/support"
          element={
            <Layout>
              <Support />
            </Layout>
          }
        />
        <Route
          path="/notifications"
          element={
            <Layout>
              <Notifications />
            </Layout>
          }
        />
        <Route
          path="/settings"
          element={
            <Layout>
              <UserSettings />
            </Layout>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
