import React from "react";
import { Paper, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const MetricsCard = React.memo(({ title, value }) => (
  <Paper elevation={2} className="p-3 flex flex-col justify-between">
    <h3 className="text-base text-gray-500 font-medium">
      {title}{" "}
      {title == "Carbon Reduction" && (
        <Tooltip title="Using Mckinsey Factor 1.4" arrow>
          <InfoIcon sx={{ fontSize: 15 }} />
        </Tooltip>
      )}
    </h3>
    <p className="text-2xl font-medium text-gray-700">{value}</p>
  </Paper>
));

export default MetricsCard;
