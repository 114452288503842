import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button, Alert, Typography } from "@mui/material";
import background from "../assets/background.jpg";
import { authService } from "../api/authService";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Clear previous messages and errors
    setMessage("");
    setError("");

    try {
      const response = await authService.forgotPassword(email);

      if (response.message === "success") {
        setMessage(`A password recovery email has been sent to ${email}`);

        setTimeout(() => {
          navigate("/");
        }, 3000); // Redirect to login after 3 seconds
      } else {
        setError("Failed to send recovery email. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("An error occurred. Please try again later.");
    }
  };

  return (
    <div
      className="flex justify-center items-center min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="bg-white shadow-lg rounded-xl p-6 w-11/12 sm:w-96">
        <form onSubmit={handleSubmit}>
          <div className="text-center mb-6">
            <Typography variant="h5" className="font-bold mt-2">
              FORGOT PASSWORD
            </Typography>
          </div>

          {message && (
            <Alert severity="success" className="mb-4">
              {message}
            </Alert>
          )}
          {error && (
            <Alert severity="error" className="mb-4">
              {error}
            </Alert>
          )}

          <TextField
            fullWidth
            label="Email"
            name="email"
            type="email"
            size="small"
            margin="dense"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            fullWidth
            type="submit"
            variant="contained"
            sx={{ textTransform: "none", marginTop: "16px" }}
          >
            Send Recovery Email
          </Button>
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;
