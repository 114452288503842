import React from "react";
import { TextField, Select, MenuItem, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const DashboardSearchFilters = React.memo(
  ({
    searchTerm,
    timeLimit,
    onSearchChange,
    onTimeLimitChange,
    onCreateClick,
  }) => {
    return (
      <div className="flex justify-between items-center gap-2 mb-4">
        <TextField
          label="Search Projects"
          variant="outlined"
          value={searchTerm}
          size="small"
          onChange={onSearchChange}
        />
        <div className="flex items-center gap-2">
          <Select
            size="small"
            autoWidth
            displayEmpty
            value={timeLimit}
            onChange={onTimeLimitChange}
          >
            <MenuItem value="all time">All Time</MenuItem>
            <MenuItem value="last day">Last Day</MenuItem>
            <MenuItem value="last 7 days">Last 7 Days</MenuItem>
            <MenuItem value="month to date">Month To Date</MenuItem>
            <MenuItem value="year to date">Year To Date</MenuItem>
          </Select>

          <Button
            variant="contained"
            onClick={onCreateClick}
            sx={{ textTransform: "none", height: "40px" }}
            startIcon={<AddIcon />}
          >
            Create New Project
          </Button>
        </div>
      </div>
    );
  }
);

export default DashboardSearchFilters;
