import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FormControlLabel,
  TextField,
  Button,
  Alert,
  Typography,
  Checkbox,
} from "@mui/material";
import logo from "../assets/logo.png";
import background from "../assets/background.jpg";
import { authService } from "../api/authService";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Clear previous errors

    try {
      const response = await authService.login(email, password);
      const token = response.data.token;
      localStorage.setItem("jwtToken", token); // Store JWT token in local storage
      window.location.href = "/dashboard"; // Redirect to dashboard
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div
      className="flex justify-center items-center min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="bg-white shadow-lg rounded-xl p-6 w-11/12 sm:w-96">
        <form onSubmit={handleSubmit}>
          <div className="text-center mb-6">
            <img src={logo} alt="App Logo" className="mx-auto h-24 w-auto" />
            <Typography variant="h5" className="font-bold mt-2">
              LOGIN
            </Typography>
          </div>

          {error && (
            <Alert severity="error" className="mb-4">
              {error}
            </Alert>
          )}

          <div className="flex flex-col gap-1">
            <TextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              size="small"
              margin="dense"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              fullWidth
              label="Password"
              name="password"
              type="password"
              size="small"
              margin="dense"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="flex justify-between items-center my-4">
            <FormControlLabel
              control={<Checkbox />}
              label={<span className="text-sm">Remember me</span>}
            />
            <span
              onClick={() => navigate("/forgot-password")}
              className="text-blue-600 text-sm cursor-pointer font-bold hover:underline"
            >
              Forgot Password?
            </span>
          </div>

          <Button fullWidth type="submit" variant="contained">
            LOGIN
          </Button>

          <Typography variant="body2" className="text-center pt-4">
            Don’t have an account?{" "}
            <span
              className="text-blue-600 cursor-pointer font-bold hover:underline"
              onClick={() => navigate("/signup")}
            >
              Sign up
            </span>
          </Typography>
        </form>
      </div>
    </div>
  );
}

export default Login;
