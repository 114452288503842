import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Divider } from "@mui/material";
import Loading from "../components/Loading";
import Error from "../components/Error";
import { dashboardService } from "../api/dashboardService";
import MetricsCard from "../components/MetricsCard";
import ProjectTable from "../components/ProjectTable";
import CreateProjectModal from "../components/CreateProjectModal";
import SearchFilters from "../components/DashboardSearchFilters";

const INITIAL_PROJECT_STATE = {
  name: "",
  project_client_name: "",
  location: "",
  expected_due_date: "",
  uploaded_files: null,
};

const INITIAL_METRICS_STATE = {
  total_projects: 0,
  total_waste_percent: 0,
  metric_tonnes_processed: 0,
  total_patterns_generate: 0,
  metric_tonnes_waste_generated: 0,
  carbon_reduction: 0,
};

const Dashboard = () => {
  const [metrics, setMetrics] = useState(INITIAL_METRICS_STATE);
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [timeLimit, setTimeLimit] = useState("all time");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newProject, setNewProject] = useState(INITIAL_PROJECT_STATE);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState("created_at");
  const [order, setOrder] = useState("desc");

  const fetchDashboardData = useCallback(async () => {
    try {
      const [metricsData, projectsData] = await Promise.all([
        dashboardService.getDashboardMetrics(timeLimit),
        dashboardService.getProjects(timeLimit),
      ]);

      setMetrics((prevMetrics) => ({
        ...prevMetrics,
        ...metricsData,
      }));
      setProjects(Array.isArray(projectsData) ? projectsData : []);
      setError(null);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      setError(
        error.response?.data?.message ||
          "An error occurred while fetching data."
      );
    } finally {
      setLoading(false);
    }
  }, [timeLimit]);

  // Sort and filter projects
  const sortedAndFilteredProjects = useMemo(() => {
    // First apply search filter
    let filtered = searchTerm
      ? projects.filter((project) =>
          project.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : projects;

    // Then sort
    return [...filtered].sort((a, b) => {
      const aValue = a[orderBy] ?? "";
      const bValue = b[orderBy] ?? "";

      // Handle date fields
      if (orderBy === "created_at" || orderBy === "expected_due_date") {
        const dateA = new Date(aValue);
        const dateB = new Date(bValue);
        return order === "asc"
          ? dateA.getTime() - dateB.getTime()
          : dateB.getTime() - dateA.getTime();
      }

      // Handle string fields
      if (typeof aValue === "string" && typeof bValue === "string") {
        return order === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      // Handle numeric fields
      return order === "asc" ? aValue - bValue : bValue - aValue;
    });
  }, [projects, searchTerm, orderBy, order]);

  // Get current page of data
  const currentPageProjects = useMemo(() => {
    const startIndex = page * rowsPerPage;
    return sortedAndFilteredProjects.slice(
      startIndex,
      startIndex + rowsPerPage
    );
  }, [sortedAndFilteredProjects, page, rowsPerPage]);

  const handleSortChange = useCallback((newOrderBy, newOrder) => {
    setOrderBy(newOrderBy);
    setOrder(newOrder);
    setPage(0); // Reset to first page when sorting changes
  }, []);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setNewProject((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleFileUpload = useCallback((e) => {
    setNewProject((prev) => ({
      ...prev,
      uploaded_files: e.target.files[0],
    }));
  }, []);

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
    setNewProject(INITIAL_PROJECT_STATE);
  }, []);

  const handleSubmit = async () => {
    try {
      await dashboardService.createProject(newProject);
      fetchDashboardData();
      handleModalClose();
    } catch (error) {
      setError(
        error.response?.data?.message ||
          "An error occurred while creating the project."
      );
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, [fetchDashboardData]);

  const handleSearchChange = useCallback((e) => {
    setSearchTerm(e.target.value);
    setPage(0); // Reset to first page when search changes
  }, []);

  const handleTimeLimitChange = useCallback((e) => {
    setTimeLimit(e.target.value);
    setPage(0); // Reset to first page when time limit changes
  }, []);

  const metricsCards = [
    { title: "Total Projects", value: metrics?.total_projects || 0 },
    {
      title: "Waste Percentage",
      value: `${metrics?.total_waste_percent || 0} %`,
    },
    {
      title: "Metric Tonnes Processed",
      value: metrics?.metric_tonnes_processed || 0,
    },
    {
      title: "Patterns Generated",
      value: metrics?.total_patterns_generate || 0,
    },
    {
      title: "Waste Generated",
      value: `${metrics?.metric_tonnes_waste_generated || 0} MT`,
    },
    { title: "Carbon Reduction", value: `${metrics?.carbon_reduction || 0}` },
  ];

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  return (
    <div className="w-full mx-auto">
      <h2 className="text-4xl text-gray-700 font-semibold mb-8">
        Dashboard Overview
      </h2>

      <div className="flex flex-col justify-center gap-6">
        <div className="grid md:grid-cols-3 lg:grid-cols-6 sm:grid-cols-2 grid-cols-1 gap-4">
          {metricsCards.map((metric, index) => (
            <MetricsCard key={index} {...metric} />
          ))}
        </div>

        <Divider />

        <div>
          <h3 className="text-2xl text-gray-700 font-semibold mb-4">
            Recent Projects
          </h3>

          <SearchFilters
            searchTerm={searchTerm}
            timeLimit={timeLimit}
            onSearchChange={handleSearchChange}
            onTimeLimitChange={handleTimeLimitChange}
            onCreateClick={() => setIsModalOpen(true)}
          />

          <ProjectTable
            projects={currentPageProjects}
            page={page}
            rowsPerPage={rowsPerPage}
            totalCount={sortedAndFilteredProjects.length}
            onPageChange={(_, newPage) => setPage(newPage)}
            onRowsPerPageChange={(e) => {
              setRowsPerPage(parseInt(e.target.value, 10));
              setPage(0);
            }}
            orderBy={orderBy}
            order={order}
            onSortChange={handleSortChange}
          />

          <CreateProjectModal
            open={isModalOpen}
            onClose={handleModalClose}
            projectData={newProject}
            onInputChange={handleInputChange}
            onFileUpload={handleFileUpload}
            onSubmit={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
