import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Input,
} from "@mui/material";

const CreateProjectModal = ({
  open,
  onClose,
  projectData,
  onInputChange,
  onFileUpload,
  onSubmit,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ textAlign: "center", fontSize: "25px" }}>
        Create New Project
      </DialogTitle>

      <DialogContent>
        <TextField
          label="Project Name"
          name="name"
          fullWidth
          size="small"
          margin="dense"
          value={projectData.name}
          onChange={onInputChange}
        />
        <TextField
          label="Project Client Name"
          name="project_client_name"
          fullWidth
          size="small"
          margin="dense"
          value={projectData.project_client_name}
          onChange={onInputChange}
        />
        <TextField
          label="Location"
          name="location"
          fullWidth
          size="small"
          margin="dense"
          value={projectData.location}
          onChange={onInputChange}
        />
        <TextField
          label="Expected Due Date"
          name="expected_due_date"
          type="date"
          fullWidth
          size="small"
          margin="dense"
          InputLabelProps={{ shrink: true }}
          value={projectData.expected_due_date}
          onChange={onInputChange}
        />

        <Input
          label="Upload File"
          name="uploaded_files"
          type="file"
          margin="dense"
          InputLabelProps={{ shrink: true }}
          onChange={onFileUpload}
          className="mt-1 before:!border-none after:!border-none"
        />
      </DialogContent>

      <DialogActions sx={{ marginBottom: "16px", marginRight: "16px" }}>
        <Button
          onClick={onSubmit}
          color="primary"
          variant="contained"
          sx={{ textTransform: "none" }}
        >
          Submit
        </Button>
        <Button
          onClick={onClose}
          color="error"
          variant="contained"
          sx={{ textTransform: "none" }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(CreateProjectModal);
