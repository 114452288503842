import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";

function ResetPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordAgain, setNewPasswordAgain] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  // Extract token from URL query parameter
  const token = new URLSearchParams(location.search).get("token");

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission

    if (newPassword !== newPasswordAgain) {
      setMessage("Passwords do not match. Please try again.");
      return;
    }

    // Call the backend API
    fetch(`${API_BASE_URL}/api/reset_password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        new_password: newPassword,
        new_password_again: newPasswordAgain,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "success") {
          setMessage("Password updated successfully.");
          // Redirect to login page after successful password update
          setTimeout(() => {
            navigate("/"); // Redirect to login page
          }, 3000); // Delay redirect for 3 seconds so the user can see the success message
        } else {
          setMessage("Failed to update password. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setMessage("An error occurred. Please try again later.");
      });
  };

  return (
    <div className="forgot-password-container">
      <form className="forgot-password-form" onSubmit={handleSubmit}>
        <h2>Reset Password</h2>
        {message && <p>{message}</p>}
        <input
          type="password"
          placeholder="Enter new password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Re-enter new password"
          value={newPasswordAgain}
          onChange={(e) => setNewPasswordAgain(e.target.value)}
          required
        />
        <button type="submit">Update Password</button>
      </form>
    </div>
  );
}

export default ResetPassword;
