import apiClient from "./apiClient";

export const projectService = {
  getProjectData: async (projectId) => {
    try {
      const response = await apiClient.get(
        `/api/dashboard/get_project_stock_summary`,
        {
          params: { project_id: projectId },
        }
      );
      return response.data.data || response.data;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Failed to fetch project data"
      );
    }
  },

  getProjectCuts: async (projectId) => {
    try {
      const response = await apiClient.get(`/api/dashboard/get_project_cuts`, {
        params: { project_id: projectId },
      });
      return response.data;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Failed to fetch project cuts"
      );
    }
  },

  getProjectCutsForWidth: async (width, projectId) => {
    try {
      const response = await apiClient.get(
        `/api/dashboard/get_project_cuts_for_width`,
        {
          params: { width: width, project_id: projectId },
        }
      );
      return response.data.data || response.data;
    } catch (error) {
      throw new Error(
        error.response?.data?.message ||
          "Failed to fetch project cuts for width"
      );
    }
  },
};
