import React from "react";

const Support = () => {
  return (
    <div className="w-full mx-auto">
      <h2 className="text-4xl text-gray-700 font-semibold mb-8">
        Help / Support
      </h2>
    </div>
  );
};

export default Support;
