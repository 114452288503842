import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tabs, Tab, Box, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Error from "../components/Error";
import Loading from "../components/Loading";
import Cuts from "../components/Cuts";
import StockSummary from "../components/StockSummary";
import { projectService } from "../api/projectService";

const ProjectPage = () => {
  const [projectData, setProjectData] = useState(null);
  const [selectedWidth, setSelectedWidth] = useState("total");
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { projectId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const loadProjectData = async () => {
      try {
        setLoading(true);
        const data = await projectService.getProjectData(projectId);
        setProjectData(data);
        setSelectedWidth("total");
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadProjectData();
  }, [projectId]);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }
  if (!projectData || !projectData.project_summary)
    return (
      <div className="fixed inset-0 flex items-center justify-center">
        No project data available
      </div>
    );

  return (
    <div className="w-full mx-auto">
      <div className="flex w-full items-center justify-between mb-8">
        <h2 className="text-4xl text-gray-700 font-semibold">
          {projectData.name}
        </h2>

        <Button
          variant="contained"
          sx={{ textTransform: "none" }}
          onClick={() => navigate("/dashboard")}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
      </div>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
        >
          <Tab label="Stock Summary" />
          <Tab label="Cuts" />
        </Tabs>
      </Box>

      {activeTab === 0 && (
        <StockSummary
          projectData={projectData}
          selectedWidth={selectedWidth}
          setSelectedWidth={setSelectedWidth}
        />
      )}
      {activeTab === 1 && <Cuts />}
    </div>
  );
};

export default ProjectPage;
