import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Loading from "./Loading";
import Error from "./Error";
import {
  MenuItem,
  Select,
  Button,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { projectService } from "../api/projectService";

const Cuts = () => {
  const [widthOptions, setWidthOptions] = useState([]);
  const [selectedWidth, setSelectedWidth] = useState("");
  const [cutsData, setCutsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { projectId } = useParams();

  // New states for download handling
  const [downloading, setDownloading] = useState({
    all: false,
    pdf: false,
    mappingData: false,
    mappingSummary: false,
    mappingId: false,
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const showNotification = (message, severity = "success") => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const fetchWidthOptions = useCallback(async () => {
    try {
      const data = await projectService.getProjectCuts(projectId);
      setWidthOptions(data);
      if (data?.data?.length > 0) {
        setSelectedWidth(data.data[0].width);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [projectId]);

  const fetchCutsForWidth = useCallback(
    async (width) => {
      try {
        const data = await projectService.getProjectCutsForWidth(
          width,
          projectId
        );
        setCutsData(data);
      } catch (error) {
        setError(error.message);
      }
    },
    [projectId]
  );

  useEffect(() => {
    fetchWidthOptions();
  }, [fetchWidthOptions]);

  useEffect(() => {
    if (selectedWidth) {
      fetchCutsForWidth(selectedWidth);
    }
  }, [selectedWidth, fetchCutsForWidth]);

  const renderCutPattern = (values) => {
    const segments = values.split("#");
    return (
      <div className="flex w-full h-[30px] border rounded overflow-hidden">
        {segments.map((value, index) => (
          <div
            key={index}
            className="flex items-center justify-center text-white text-xs drop-shadow-sm"
            style={{
              flex: parseInt(value),
              backgroundColor:
                index === segments.length - 1
                  ? "#333" // Apply dark grey color for offcut
                  : `hsl(${(index * 30) % 360}, 70%, 60%)`,
            }}
          >
            {value}
          </div>
        ))}
      </div>
    );
  };

  const handleDownloadPDF = async () => {
    setDownloading((prev) => ({ ...prev, pdf: true }));
    try {
      const pdf = new jsPDF("p", "mm", "a4");
      const pageWidth = pdf.internal.pageSize.getWidth();

      // Get the table element - update selector for MUI Table
      const tableElement = document.querySelector(".MuiTableContainer-root");

      if (tableElement) {
        // Create centered header text
        pdf.setFontSize(16);
        pdf.text(
          `Cuts Pattern for ${selectedWidth}mm Rebar Diameter`,
          pageWidth / 2,
          20,
          { align: "center" }
        );
        pdf.setFontSize(10);

        // Prepare data for the table
        const tableRows = [];
        const cutsTableBody = tableElement.querySelector(".MuiTableBody-root");

        // Iterate through MUI TableRows
        const rows = cutsTableBody.querySelectorAll(".MuiTableRow-root");
        for (const row of rows) {
          const cells = row.querySelectorAll(".MuiTableCell-root");

          // Get the pattern div from the last cell
          const patternCell = cells[3];
          const patternDiv = patternCell.querySelector("div:last-child"); // Select the div containing the pattern

          // Capture the cut pattern
          const patternCanvas = await html2canvas(patternDiv, {
            scale: 2,
            backgroundColor: null,
          });

          tableRows.push([
            cells[0].textContent,
            cells[1].textContent,
            cells[2].textContent,
            patternCanvas,
          ]);
        }

        // Configure autoTable
        pdf.autoTable({
          startY: 30,
          head: [["Sr No", "Quantity", "Offcut", "Cuts Pattern"]],
          body: tableRows,
          tableWidth: "wrap",
          horizontalPageBreak: true,
          horizontalPageBreakRepeat: 0,
          margin: {
            left: (pageWidth - 170) / 2,
            right: (pageWidth - 170) / 2,
          },
          columnStyles: {
            0: { cellWidth: 20 },
            1: { cellWidth: 30 },
            2: { cellWidth: 30 },
            3: { cellWidth: 90 },
          },
          didDrawCell: function (data) {
            // Check if the cell is the cut pattern cell and a canvas exists
            if (
              data.column.index === 3 &&
              data.cell.raw instanceof HTMLCanvasElement
            ) {
              const canvas = data.cell.raw;
              const imgData = canvas.toDataURL("image/png");

              // Calculate scaled dimensions to maintain aspect ratio
              const maxWidth = data.cell.width - 4;
              const maxHeight = data.cell.height - 4;
              const imgAspectRatio = canvas.width / canvas.height;

              let drawWidth, drawHeight;
              if (imgAspectRatio > 1) {
                drawWidth = Math.min(maxWidth, canvas.width);
                drawHeight = drawWidth / imgAspectRatio;
              } else {
                drawHeight = Math.min(maxHeight, canvas.height);
                drawWidth = drawHeight * imgAspectRatio;
              }

              // Center the image within the cell
              const xOffset = (maxWidth - drawWidth) / 2;
              const yOffset = (maxHeight - drawHeight) / 2;

              pdf.addImage(
                imgData,
                "PNG",
                data.cell.x + 2 + xOffset,
                data.cell.y + 2 + yOffset,
                drawWidth,
                drawHeight
              );
            }
          },
          styles: {
            overflow: "linebreak",
            fontSize: 10,
            cellPadding: 2,
          },
          theme: "grid",
        });

        // Save the PDF
        pdf.save(`cuts_for_${selectedWidth}mm.pdf`);
      }
      showNotification("PDF downloaded successfully");
    } catch (error) {
      showNotification("Failed to generate PDF", "error");
    } finally {
      setDownloading((prev) => ({ ...prev, pdf: false }));
    }
  };

  const handleMappingDataDownload = () => {
    setDownloading((prev) => ({ ...prev, mappingData: true }));
    try {
      const selectedData = widthOptions.data.find(
        (item) => item.width === selectedWidth
      );
      if (selectedData) {
        window.open(selectedData.file_url);
        showNotification("Mapping data downloaded successfully");
      }
    } catch (error) {
      showNotification("Failed to download mapping data", "error");
    } finally {
      setDownloading((prev) => ({ ...prev, mappingData: false }));
    }
  };

  const handleMappingSummaryDownload = () => {
    setDownloading((prev) => ({ ...prev, mappingSummary: true }));
    try {
      window.open(widthOptions.mapping_summary_url);
      showNotification("Mapping summary downloaded successfully");
    } catch (error) {
      showNotification("Failed to download mapping summary", "error");
    } finally {
      setDownloading((prev) => ({ ...prev, mappingSummary: false }));
    }
  };

  const handleMappingIdDownload = () => {
    setDownloading((prev) => ({ ...prev, mappingId: true }));
    try {
      window.open(widthOptions.id_map_url);
      showNotification("Mapping ID downloaded successfully");
    } catch (error) {
      showNotification("Failed to download mapping ID", "error");
    } finally {
      setDownloading((prev) => ({ ...prev, mappingId: false }));
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <div className="p-5 mt-5">
      <div className="flex justify-between mb-6 gap-3">
        <div className="flex gap-3 items-center">
          <InputLabel id="label">Rebar Diameter:</InputLabel>
          <Select
            labelId="label"
            value={selectedWidth}
            onChange={(e) => setSelectedWidth(e.target.value)}
            size="small"
            autoWidth
            displayEmpty
          >
            {widthOptions.data.map((option) => (
              <MenuItem key={option.width} value={option.width}>
                {option.width}mm
              </MenuItem>
            ))}
          </Select>
        </div>

        <div className="flex gap-3">
          <Button
            variant="contained"
            sx={{ textTransform: "none" }}
            startIcon={
              downloading.all ? (
                <CircularProgress size={20} />
              ) : (
                <FileDownloadIcon />
              )
            }
            disabled={downloading.all}
          >
            Download All
          </Button>

          <Button
            variant="contained"
            sx={{ textTransform: "none" }}
            startIcon={
              downloading.pdf ? (
                <CircularProgress size={20} />
              ) : (
                <FileDownloadIcon />
              )
            }
            onClick={handleDownloadPDF}
            disabled={downloading.pdf}
          >
            Download as PDF
          </Button>

          <Button
            variant="contained"
            sx={{ textTransform: "none" }}
            startIcon={
              downloading.mappingData ? (
                <CircularProgress size={20} />
              ) : (
                <FileDownloadIcon />
              )
            }
            onClick={handleMappingDataDownload}
            disabled={downloading.mappingData}
          >
            Mapping Data
          </Button>

          <Button
            variant="contained"
            sx={{ textTransform: "none" }}
            startIcon={
              downloading.mappingSummary ? (
                <CircularProgress size={20} />
              ) : (
                <FileDownloadIcon />
              )
            }
            onClick={handleMappingSummaryDownload}
            disabled={downloading.mappingSummary}
          >
            Mapping Summary
          </Button>

          <Button
            variant="contained"
            sx={{ textTransform: "none" }}
            startIcon={
              downloading.mappingId ? (
                <CircularProgress size={20} />
              ) : (
                <FileDownloadIcon />
              )
            }
            onClick={handleMappingIdDownload}
            disabled={downloading.mappingId}
          >
            Mapping ID
          </Button>
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "600", background: "#f8f9fa" }}>
                Sr No
              </TableCell>
              <TableCell sx={{ fontWeight: "600", background: "#f8f9fa" }}>
                Quantity
              </TableCell>
              <TableCell sx={{ fontWeight: "600", background: "#f8f9fa" }}>
                Offcut
              </TableCell>
              <TableCell sx={{ fontWeight: "600", background: "#f8f9fa" }}>
                Cuts pattern
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cutsData.map((cut, index) =>
              cut.values_list.map((pattern, subIndex) => (
                <TableRow key={`${index}-${subIndex}`}>
                  <TableCell>{subIndex + 1}</TableCell>
                  <TableCell>{cut.quantity_list[subIndex]}</TableCell>
                  <TableCell>
                    {pattern
                      ? pattern.split("#").pop() // Extract the last value from the pattern
                      : "-"}
                  </TableCell>
                  <TableCell>
                    <div className="text-xs mb-3">{pattern}</div>
                    {renderCutPattern(pattern)}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Cuts;
